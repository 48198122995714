/**
 * 子菜单
 */
export default {
  example: {
		'menu_100' : {
			icon: 'profile',
			title: 'Example',
			pageName: 'ExampleHelloIndex',
			params: {}
		},
  }
}
